import React from 'react'

import Layout from '../components/layout'
import { Container, Text, Heading } from "theme-ui"

export const Head = () => (
  <>
    <title>Cancel Payment</title>
    <meta name="description" content="Cancel Payment" />
  </>
)

const NotFoundPage = () => (
  <Layout variant="sidebar">
    <Container variant="layout">
    <Heading as="h1" variant="h1Centered">Payment Cancelled</Heading>
    </Container>
  </Layout>
)

export default NotFoundPage
